.NavBar {
    background-color: #1B355C;
    height: 10vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 70px 1fr 60px;
}

.logo {
  width: 70px;
  height: 100%;
  grid-column: 1;
}

.hamburger-icon {
  fill: #fcfcfc;
  display: block;
}

.hamburger-menu {
  grid-column: 3;
  height: 10vh;
  background-color: #1B355C;
  border: none;
  overflow: hidden;
}

.nav-links {
  position: absolute;
  right: 0;
  top: 0;
  text-align: left;
  background-color: #1B355C;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5em;
  list-style: none;
  padding-left: 0px;
  margin-top: 0;
  margin-bottom: 0;
  display: none;
  z-index: 10;
}

.nav-links li {
  padding: 10px 30px 10px 15px;
  background-color: none;
}

.nav-links li.active {
  background-color: #fa7f72;
}

.nav-links a {
  text-decoration: none;
  color: #fcfcfc;
}

.nav-links.active {
  display: block;
}


@media only screen and (min-width: 820px) {
    .NavBar {
      height: 100vh;
      width: 80px;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: repeat(5, 80px);
    }

    .logo {
      width: 100%;
      height: 80px;
      grid-row: 1;
    }

    .nav-icons {
      list-style: none;
      grid-row: 2 / 6;
      margin: 0;
      padding: 0;
    }

    .page-icon {
      background-color: none;
    }

    .page-icon.active {
      background-color: #fa7f72;
    }

    #season-chart {
      transform: translate(15%, 15%);
    }

    #contacts-icon {
      transform: translate(15%, 15%);
    }
}