.error-wrapper {
    background-color: rgb(231, 63, 63);
    color: #fcfcfc;
    height: 50px;
    display: flex;
    justify-content: center;
}

.error-text {
    align-self: center;
}
