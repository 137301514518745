table {
    width: 100%;
    margin-top: 30px;
    border-collapse: collapse;
    text-align: left;
}

.ver-mais-button {
    margin-top: 5px;
    margin-bottom: 5px;
}

thead td {
    font-weight: 900;
}

tbody td {
    border: 1px solid;
    border-left: none;
    padding-left: 5px;
}

tbody td:nth-last-child(1) {
    border-right: none;
}