h3 {
    margin-left: 5%;
}

.single-contact-info {
    display: flex;
    flex-wrap: wrap;
}

.back-button-wrapper {
    width: 100%;
}

.back-button {
    border: none;
    background-color: #fcfcfc;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 800;
}

.back-button::before {
    content: '\2190';
    padding-right: 10px;
    font-weight: 800;
}

.info-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 1fr /*0.2fr return when decision about edit button is made*/; 
}

.info-wrapper path {
    transform: translate(20%, 15%);
}

.info-wrapper svg {
    width: 100%;
    height: 100%;
}

.info-wrapper h1 {
    font-size: 18;
}

.info-wrapper p {
    font-size: 8;
}

.info {
    grid-column: 2;
}

.info p {
    margin: 5px 0px 5px;
}

.edit-contact-button {
    grid-column: 3;
    height: 23px;
    width: 55px;
    border: none;
    border-radius: 4px;
    color: #fcfcfc;
    background-color: #0094e8;
    display: none;
}

.aggregate-buy-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
    position: relative;
}

.aggregate-buy-info .lds-ring.black {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.total-orders {
    grid-column: 1;
    margin-left: 0;
}

.orders-per-month {
    grid-column: 2;
}

.total-orders h3, .orders-per-month h3 {
    font-size: 20px;
}

.total-orders p, .orders-per-month p {
    font-size: 30px;
}

.single-contact-charts {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.accordion-wrapper {
    position: relative;
}

.accordion-wrapper .lds-ring.black {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}


@media only screen and (min-width: 820px) {
    .general-info-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .single-contact-info {
        grid-column: 1;
    }

    .aggregate-buy-info {
        grid-column: 2;
    }

    

}