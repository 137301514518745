.page-config {
    display: grid;
    grid-template-columns: 1fr 2fr /*1fr*/;
    min-height: 100px;
}

.filter {
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-bar {
    grid-column: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    flex: 1 1;
}

.search-bar input {
    width: 100%;
    height: 35px;
    font-size: 14px;
    background-image: url('../../magnifying-glass-svgrepo-com.svg');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 40px;
    box-shadow: none;
    margin-right: 10px;
}

.add-contacts {
    /*grid-column: 3;*/
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.config-button {
    background-color: #1B355C;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.config-button > svg {
    height: 25px;
    width: 25px;
}

.content-wrapper {
    text-align: center;
}

@media only screen and (min-width: 820px) {
    .config-button {
        width: 60px;
        border-radius: 10%;
    }
}