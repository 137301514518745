.login-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-color: #fa7f72;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3em;
    font-weight: 600;
    color: #fcfcfc;
}

.login-page .logo-container {
    width: 100%;
    height: 300px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.login-page .logo {
    height:300px;
    width: 300px;
}

.login-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 400px;
}

.login-form input {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8em;
}

.login-submit-wrapper {
    margin-top: 5px;
    width: 200px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fcfcfc;
    background-color: #1B355C;
    cursor: pointer;
    text-align: center;
}

.login-form input[type='submit'] {
    border: none;
    color: #fcfcfc;
    background-color: transparent;
    cursor: pointer;
    height: 25px;
    width: 180px;
}

.login-error-message {
    position: absolute;
    background-color: rgb(231, 63, 63);
    width: 100%;
    text-align: center;
}