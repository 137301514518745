.pie-legend-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
}

.pie-legend-wrapper .pie-legend-item div {
    width: 15px;
    height: 15px;
}

.pie-legend-item {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    align-items: center;
    justify-items: center;
}

.pie-legend-item p {
    margin-right: 10px;
}