.accordion-button {
    font-size: 1.2em;
    font-family: 'Montserrat', sans-serif;
    background-color: #fcfcfc;
    color: black;
    text-align: left;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 8px;
    cursor: pointer;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: none;
    border-right: none;
}

.accordion-info-wrapper {
    background-color: #ececec;
}

.accordion-info-wrapper p {
    margin: 0;
}

.ver-mais-button {
    color: #fcfcfc;
    background-color: #fa7f72;
    border-radius: 30px;
    border: none;
}

.accordion-switch {
    transform: rotate(-90deg);
}

/* Activate toggle */
.accordion-info-wrapper {
    height: 0;
    overflow: hidden;
}

.accordion-info-wrapper.open {
    height: auto;
}

.accordion-item.active .accordion-button .accordion-switch {
    transform: rotate(90deg);
}