.filter-form-wrapper {
    display: none;
}

.filter-form-wrapper.active {
    position: fixed;
    height: auto;
    width: 90%;
    background-color: #1B355C;
    top: 65px;
    left: 30px;
    border-radius: 5px;
    color: #fcfcfc;
    display: grid;
    grid-template-columns: 0.9fr 0.1fr;
    grid-template-rows: 50px 0.9fr;
}

.filter-form-wrapper h3 {
    font-weight: 900;
    grid-column: 1;
    grid-row: 1;
}

.filter-form-wrapper > button {
    grid-column: 2;
    grid-row: 1;
    width: 15px;
    height: 15px;
    justify-self: end;
    margin-top: 10px;
    margin-right: 10px;
    background-color: #1B355C;
    position: relative;
    border: none;
    cursor: pointer;
}

.filter-form-wrapper > button::after {
    content: 'X';
    color: #fcfcfc;
    font-size: 20px;
    position: absolute;
    right: 0px;
    top: -5px;
}

.filter-form {
    margin-left: 20px;
    grid-column: 1 / 3;
    overflow-y: auto;
    position: relative;
}

.filter-form input {
    margin-right: 30px;
}

.form-selection-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.form-selection-container div {
    margin-left: 10px;
}

#filter-button {
    background-color: #fa7f72;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: #fcfcfc;
    border: none;
    border-radius: 10px;
    padding: 5px;
    width: 30%;
    cursor: pointer;
}

#filter-button-label {
    width: 100%;
    margin: 0;
    text-align: center;
}

.filter-search-results {
    position: absolute;
    background-color: #fcfcfc;
    color: black;
    width: 150px;
    height: auto;
    max-height: 150px;
    z-index: 2;
    overflow-y: scroll;
}

.filter-search-results p {
    cursor: pointer;
    width: 100%;
}

.filter-search-results p:hover {
    background-color: #e3e3e3;
}