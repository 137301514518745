.form-selection-flex-container {
    border: solid 2px #fa7f72;
    border-radius: 10px;
    background-color: #f8b6af;
    min-width: 110px;
    max-height: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.form-selection-flex-container button {
    background-color: #f8b6af;
    border: none;
    width: 15px;
    height: 15px;
    margin: 0px;
    padding: 0px;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #fcfcfc;
}

/* .form-selection-flex-container button::after {
    background-color: none;
    color: #fcfcfc;
    content: x;
    border: none;
    font-size: 12px;
    padding: 0px;
    position: absolute;
    left: 0;
    top: 1px;
} */