.date-range-wrapper {
    width: 100%;
    margin: 30px 0px 50px 0px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.date-range-input {
    width: 150px;
    height: 30px;
    border: solid 2px black;
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
    text-align: center;
    align-items: center;
}

.date-range-input p {
    grid-column: 2;
    margin: 0;
}

.date-range-picker {
    position: absolute;
    top: 34px;
    display: none;
    border: solid 1px black;
    border-radius: 10px;
}

.date-range-picker.active {
    display: block;
    z-index: 10;
}

@media only screen and (min-width: 820px) {
    .date-range-wrapper {
        width: 50%;
        grid-column: 2;
        grid-row: 1;
    }
}