.graph-wrapper {
    width: 100%;
}

.individual-graph-wrapper {
    width: 100%;
}

.sales-wrapper h1, .sales-wrapper p {
    width: 100%;
}

.sales-wrapper {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
}

@media only screen and (min-width: 820px) {
    .general-sales-info {
        display: grid;
        grid-template-columns: 1fr 1.8fr;
        grid-template-rows: 1fr;
        justify-items: center;
    }

    .sales-wrapper {
        width: 50%;
        grid-column: 1;
        grid-row: 1;
        flex-wrap: nowrap;
        align-items: center;
    }

    .individual-graph-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        position: relative;
    }

    .individual-graph-wrapper .lds-ring.black {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}