.donut-legend-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
}

.donut-legend-wrapper .donut-legend-item div {
    width: 15px;
    height: 15px;
}

.donut-legend-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.donut-legend-item p {
    margin-right: 5px;
}