#root {
  overflow-x: hidden;
}

.App {
  display: grid;
  grid-template-rows: 10vh auto;
}

.App-header {
  grid-row: 1;
}

.App-content {
  grid-row: 2;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (min-width: 820px) {
  .App {
    grid-template-columns: 80px auto;
    grid-template-rows: 100vh;
  }

  .App-header {
    grid-column: 1;
  }
  
  .App-content {
    grid-column: 2;
    grid-row: 1;
    overflow-y: scroll;
  }
  
}