.view-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px; 
}

.view-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.view-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 150px;
    height: 50px;
    background: #c0c0c0;
    border-radius: 100px;
    border: solid 1px black;
    position: relative;
    transition: background-color .2s;
}

.view-switch-label .view-switch-switch {
    color: #000;
    padding: 0px 15px 0px 15px;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 45px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fcfcfc;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.view-switch-label .view-switch-switch::before {
    content: 'Total';
    position: relative;
    top: 12px;
}

.view-switch-checkbox:checked + .view-switch-label .view-switch-switch {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.view-switch-checkbox:checked + .view-switch-label .view-switch-switch::before {
    content: 'Categoria';
}